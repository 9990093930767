// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-akty-prawne-js": () => import("./../../../src/pages/akty-prawne.js" /* webpackChunkName: "component---src-pages-akty-prawne-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inwestycje-js": () => import("./../../../src/pages/inwestycje.js" /* webpackChunkName: "component---src-pages-inwestycje-js" */),
  "component---src-pages-ogloszenia-js": () => import("./../../../src/pages/ogloszenia.js" /* webpackChunkName: "component---src-pages-ogloszenia-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-przetargi-js": () => import("./../../../src/pages/przetargi.js" /* webpackChunkName: "component---src-pages-przetargi-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wladze-js": () => import("./../../../src/pages/wladze.js" /* webpackChunkName: "component---src-pages-wladze-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

